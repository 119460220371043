<template>
  <div id="app">
    <center>
      <h1>IP-INFO</h1>
      <input v-model="inputIP" placeholder="Введите IP Адрес" />
      <button class="get" @click="getIP">Получить</button>
      <div class="ip-info">
        <div v-if="res !== null" class="output">
          <p class="text">Имя хоста: {{ res.hostname }}</p>
          <p class="text">IP Адрес: {{ res.ip }}</p>
          <p class="text">Страна:  {{ res.country }}</p>
          <p class="text">Регион:  {{ res.region }}</p>
          <p class="text">Город: {{ res.city }}</p>
          <p class="text">Примерное место положение: {{ res.loc }}</p>
        </div>
      </div>
    </center>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      res: null,
      inputIP: '',
    };
  },
  methods: {
    async getIP() {
      try {
        const response = await axios.get(`https://ipinfo.io/${this.inputIP}?token=f2534d3a29dd2a`);
        this.res = response.data;
        console.log(this.res);
      } catch (error) {
        console.error('Error fetching IP address:', error.message);
      }
    },
  },
};
</script>

<style>
  * {
    padding: 0;
    margin: 0;
    font-family: 'Pixelify Sans', sans-serif;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .text {
    width: 100vw;
    font-size: 30px;
    white-space: nowrap;
    overflow: hidden;
    animation: printed_text 1s steps(30);
  }

  @keyframes printed_text {
    from {
      width: 0;
    }
  }
</style>
